@secondary-color: #306D9C;
@header-color: #CDDBE3;
@error-color: #993366;
@good-color: #339966;
.hover-none:hover {
    color: #fff;
}
.defect-completed {
    color: #ccc;
}
.delete-button {
    background: none;
}
.help-block{
    color: red;
}
.table th:last-of-type {
    text-align: right;
}
#login-form_block {
    position: absolute;
    top: 20%;
}
#main-container {
    min-height: calc(100vh - 43px);
}

.login-header {
    padding: 24px 0 0 0;
}

.with-subrows {
    cursor: pointer;
}

.elpas-menu__top-background {
    background-color: white;
    padding: 0;
    margin: 0 auto !important;
    text-align: center;
    padding-top: 8px;
    height: 76px
}

.edit-icon {
    width: 12px;
    height: 12px;
}

.custom-header {
    border-bottom:1px solid rgba(0,0,0,.15);
    height: 77px;
}

.image-margin-fix {
    width: 48px !important;
    float: left;
}

.status-line-background {
    display: block;
    width: 90%;
    margin-left: 5%;
    height: 8px;
    background: lightgray;
    border-radius: 15px;
    margin-bottom: 8px;

    .status-line {
        background: green;
        border-radius: 8px;
        height: 100%;
    }
}

.company__company-name-wrapper {
    float: left;

    * {
        float: left;
    }
}

.custom-breadcrumbs {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    z-index: 101;
    color: white;
    background-color: @secondary-color;
    height: 40px;
    padding-top: 8px;

    * {
        color: white;
    }
}

.breadcrumb>.active{
    color: white !important;
}

#main-container {
    background-color: #F1F3F4;
}

.block-subtitle {
    padding: 0;
    line-height: 1;
    margin: 8px 0 0 0 !important;
    font-size: 12px;
}

.block-header {
    max-height: 96px;
    background-color:@header-color;

    .block-title {
        font-size: 16px;
    }
}

.block-content {
    padding: 0 20px 1px !important;
}

.card-body {
    tr {
        * {
            color: gray;
            font-weight: 400 !important;
            font-size: 14px !important;
        }
        td:first-child {
            float: left;
            width: 100%;
        }
        td:last-child {
            width: 25%;
            text-align: right;
        }
        td {
            a:hover {
                text-decoration: underline;
            }
        }
        td.table-link {
            width: 100%;
            text-align: left;
            a {
                color: #0099FF;
            }
        }

        tbody {
            margin-left: 24px;
        }
    }
    .with-subrows{
        td:first-child:after {
            float: left;
            display: block;
            margin-right: 8px;
            font-size: 14px;
            color: @secondary-color !important;
        }
    }
    .with-subrows.closed {
        td:first-child:after {
            content: '+';
            font-weight: bold;
        }
    }
    .with-subrows.opened {
        td:first-child:after {
            content: '-';
            font-weight: bold;
        }
    }
    .subrow {
        td:first-child {
            margin-left: 36px;
        }
    }
    .subrow.subrow-clear {
        td:first-child {
            margin-left: 0px;
        }
    }
}

.show-spoiler-table {
    font-size: 14px;
    color: @secondary-color !important;
    margin-left: 8px;
}

.add-new-plus {
    font-size: 24px;
    color: @secondary-color;
    margin-left: 8px;
}

.table-big {
    thead {
        background-color: @header-color;

        tr {
            th {
                font-size: 12px !important;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 12px !important;
                vertical-align: middle;
            }
            td.empty {
                color: @error-color;
                * {
                    color: @error-color;
                }
            }
            td:last-child {
                text-align: right;
            }
        }
    }
    tfoot {
        background-color: @header-color;
        width: 100%;

        td:last-child {
            text-align: right;
        }
    }
}

.table-defects {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 20%;
            }
            th:nth-child(3) {
                width: 15%;
            }
            th:nth-child(4) {
                width: 25%;
                text-align: center;
            }
            th:nth-child(5) {
                width: 15%;
            }
            th:nth-child(6) {
                width: 10%;
            }
            th:nth-child(9) {
                width: 20%;
            }
        }
    }
    tbody {
        tr {
            td:nth-child(4) {
                text-align: center;
            }
        }
    }
}

.table-single-criterias {
    thead {
        tr {
            th:first-child {
                width: 5%;
            }
            th:nth-child(2) {
                width: 5%;
            }
            th:nth-child(3) {
                width: 90%;
            }
            th:nth-child(4) {
                width: 5%;
            }
        }
    }
}

.table-files {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 5%;
                text-align: center;
            }
            th:nth-child(3) {
                width: 28%;
            }
            th:nth-child(4) {
                width: 40%;
            }
            th:nth-child(5) {
                width: 15%;
            }
            th:nth-child(6) {
                width: 10%;
            }
        }
    }
    tbody {
        tr {
            td:nth-child(2) {
                text-align: center;
            }
        }
    }
}

.table-journal {
    thead{
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 5%;
                text-align: center;
            }
            th:nth-child(3) {
                width: 30%;
            }
            th:nth-child(4) {
                width: 20%;
            }
            th:nth-child(7) {
                width: 20%;
            }
        }
    }
    tbody {
        tr {
            td:nth-child(2) {
                text-align: center;
            }
        }
    }
}

.table-plan {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 8%;
                text-align: center;
            }
            th:nth-child(3) {
                width: 25%;
            }
            th:nth-child(4) {
                width: 10%;
            }
        }
    }
    tbody {
        tr{
            td:nth-child(2) {
                text-align: center;
            }
        }
    }
}

.table-certificate-opo {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 1%;
            }
            th:nth-child(3) {
                width: 40%;
            }
            th:nth-child(4) {
                width: 20%;
            }
            th:nth-child(5) {
                width: 15%;
            }
            th:nth-child(6) {
                width: 15%;
            }
        }
    }
    tbody {
        tr.with-subrows {
            td:nth-child(3):after {
                display: block;
                float: left;
                color: @secondary-color;
                margin-right: 8px;
            }
        }
        tr.closed {
            td:nth-child(3):after {
                content: '+';
                font-weight: bold;
            }
        }
        tr.opened {
            td:nth-child(3):after {
                content: '-';
                font-weight:bold
            }
        }
        tr.subrow {
            td:nth-child(3) {
                padding-left: 32px;
                color: lightgray;
            }
        }
    }
}

.table-criterias {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 5%;
            }
            th:nth-child(3) {
                //width: 75%;
            }
        }
    }
    tbody {
        tr {
            td:last-child {
                text-align: initial !important;
            }
        }
    }
}

.table-podnad-buildings {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 5%;
            }
            th:nth-child(3) {
                width: 10%;
            }
            th:nth-child(4) {
                width: 10%;
            }
            th:nth-child(5) {
                width: 5%;
            }
            th:nth-child(6) {
                width: 5%;
            }
            th:nth-child(7) {
                width: 5%;
            }
            th:nth-child(8) {
                width: 5%;
            }
            th:nth-child(9) {
                width: 5%;
            }
            th:nth-child(10) {
                width: 30%;
            }
        }
    }
    tbody {
        tr.with-subrows {
            td:nth-child(3):after {
                display: block;
                float: left;
                color: @secondary-color;
                margin-right: 8px;
            }
        }
        tr.closed {
            td:nth-child(3):after {
                content: '+';
                font-weight: bold;
            }
        }
        tr.opened {
            td:nth-child(3):after {
                content: '-';
                font-weight: bold;
            }
        }
        tr.subrow {
            td:nth-child(3) {
                padding-left: 32px;
                color: lightgray;
            }
        }
        tr {
            td:last-child {
                text-align: initial !important;
            }
        }
    }
}

.table-objects-td {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2){
                width: 3%;
            }
            th:nth-child(3){
                width: 7%;
            }
            th:nth-child(4){
                width: 12%;
            }
            th:nth-child(5){
                width: 18%;
            }
            th:nth-child(6){
                width: 12%;
            }
            th:nth-child(7){
                width: 10%;
            }
            th:nth-child(8){
                width: 12%;
            }
        }
    }
}

.table-parts-opo {
    thead {
        tr {
            th:first-child {
                width: 1%;
            }
            th:nth-child(2) {
                width: 1%;
            }
        }
    }
}

.table-str_const {
    thead {
        tr{
            th:first-child{
                width: 1%;
            }
            th:nth-child(2) {
                width: 5%;
            }
            th:nth-child(3) {
                width: 95%;
            }
        }
    }
}
.table-orgs {
    thead {
        tr{
            th:first-child{
                width: 1%;
            }
            th:nth-child(2) {
                width: 5%;
            }
            th:nth-child(3) {
                width: 24%;
            }
            th:nth-child(4) {
                width: 20%;
            }
            th:nth-child(5) {
                width: 20%;
            }
            th:nth-child(6) {
                width: 25%;
            }
            th:nth-child(7) {
                width: 1%;
            }
        }
    }
}

.row-with-margin {
    margin-top: 24px;
    margin-bottom: 24px;
}

.filter-wrapper {
    width: 100%;

    .filter {
        float: left;
        width: 214px;
        label {
            width: 100%;
            font-style: normal;
            font-weight: 200;
            height: 24px;
            padding: 0 0 8px 0;
        }
        select {
            width:100%;
            height: 28px;
        }
        &:not(:first-child) {
            margin-left: 12px;
        }
    }
    .filter-button {
        margin-top: 28px;
        margin-left: 12px;
        background-color: @secondary-color;
        color: white;
        border: 1px solid white;
        border-radius: 4px;
        padding: 4px 8px 4px 8px;
    }
}

.table-button {
    padding: 4px 8px 4px 8px;
    background: 0;
    border-width: 2px;
    border-style: solid;

    &:not(:first-child) {
        margin-left: 12px;
    }
}

.button-good {
    border-color: @good-color;
    color: @good-color;

    &:hover {
        background-color: @good-color;
        color: white;
    }
}

.button-bad {
    border-color: @error-color;
    color: @error-color;

    &:hover {
        background-color: @error-color;
        color: white;
    }
}

.add-wrapper {
    width: 100%;

    a {
        width: 100%;
        float: right;
        text-align: right;
        color: @good-color;
    }
}

.table-pagination-wrapper {
    width: 100%;

    nav {
        .table-pagination {
            float: right;
            padding: 0;
            margin: 0 auto;

            .page-item {
                .page-link {
                    background: 0;
                }
            }
        }
    }
}

form {
    .form-group:first-child {
        padding-top: 24px;
    }
    .form-group {
        div {
            div {
                .form-hint {
                    font-size: 11px;
                }
                input[type="file"] {
                    padding: 12px 0 36px 0;
                }
            }
        }
    }
    .form-link {
        padding-top: 16px;
        a {
            color: @good-color;
        }
    }
}

.active-works-wrapper {
    width: 100%;
    div {
        float: left;
    }
    div:not(:first-child) {
        margin-left: 12px;
    }
}
.breadcrumb {
    li {
        a {
            color: white;
            text-decoration: underline;

            &:hover {
                color: white;
                text-decoration: none;
            }
        }
    }
}
.css-input.css-checkbox.css-checkbox-primary {
    width: 100%;
}

.full-width-link {
    display: block;
    width: 100%;

    &:not(:first-child) {
        margin-top: 8px;
    }

    &:last-child {
        margin-bottom: 12px;
    }
}

.link-bad {
    color: @error-color !important;

    &:hover {
        color: darkred;
    }
}

.nav-main .nav-submenu {
  position: relative;
    padding: 10px 20px;
}

.nav-main .nav-submenu a {
    display: inline;
    padding: 0;
}

.nav-main .nav-submenu:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.nav-main .nav-submenu a:hover,
.nav-main .nav-submenu a:focus {
    color: #fff;
    background: none;
}

.nav-main .nav-submenu:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: inline-block;
  font-family: 'FontAwesome';
  color: rgba(255, 255, 255, 0.25);
  content: "\f104";
}

.nav-main li.open > .nav-submenu:before {
  content: "\f107";
}

.nav-main li.open > .nav-submenu > a{
  color: #fff;
}

.map #main-container,
.blueprint #main-container  {
    height: calc(100vh - 43px);
}

.map .content,
.blueprint .content {
    padding: 55px 0 1px;
    height: 100%;
}

.map-area {
    width: 100%;
    height: 100%;
    position: relative;
}

.map-area__map {
    width: 100%;
    min-height: 700px;
    height: 100%
}

.map-area__list-wrap {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 400px;
    background: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.map-area__list-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px 25px 30px;
    border-bottom: 1px solid #EAEDEF;
}

.map-area__list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
}

.map-area__list-item {
    padding: 10px 20px 10px 30px;
    border-bottom: 1px solid #EAEDEF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-area__point-title {
    max-width: 90%;
    text-align: left;
    border: none;
    box-shadow: none;
    background: none;
    font-family: inherit;
    padding: 0;
    font-size: 16px;

    &.active {
        color: #306d9c;
    }
}

.map-area__point-title.active + span {
    color: #306d9c;
}

.map-area__open-btn {
    position: relative;
    border: none;
    box-shadow: none;
    background: none;
    font-family: inherit;
    font-size: 18px;
    color: #000;
    font-weight: 700;
    padding: 0 20px 0 0;
    max-width: 90%;
    text-align: left;

    &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        right: 0;
        top: 50%;
        transform: translateY(-6px) rotate(45deg);
        transition: all .2s;
    }

    &.open::after {
        transform: translateY(-1px) rotate(225deg);
    }
}

.map-area__drag-btn {
    border: none;
    box-shadow: none;
    height: 25px;
    width: 16px;
    background: url('../img/bento-icon.png') no-repeat center/contain;
    font-size: 0;
}

#presentationMode {
    display: none;
}

.nav-header > li > a.header-mail-link {
    height: 43px;
    line-height: 1;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .nav-button-fix {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .map .content,
    .blueprint .content {
        padding: 0 0 1px;
        margin-top: 0;
    }
}

@media screen and (max-width:767px) {
    .button-good {
        display: block;
        text-align: center;
        width: 100%;
    }
    .content {
        margin-top: 24px;
    }
    #login-form_block {
        width: 100%;
    }
    .custom-breadcrumbs {
        display: none;
    }
    .filter-wrapper {
        .filter {
            float: none;
            width: 100%;
        }
        .filter:not(:first-child) {
            margin-top: 12px;
            margin-left: 0;
        }
        .filter-button {
            margin: 12px 0 12px 0;
        }
    }

    .add-wrapper {
        a {
            float: none;
            text-align: left;
        }
    }

    .table-button {
        width: 100%;

        &:not(:first-child) {
            margin-top: 12px;
            margin-left: 0px;
        }
    }

    .active-works-wrapper {
        width: 100%;
        div {
            float: none;
        }
        div:not(:first-child) {
            margin-left: 0;
            margin-top: 8px;
        }
    }

    form {
        .form-link {
            padding-top: 0;
            padding-bottom: 8px;
        }
    }
}

@media screen and (max-width:767px) {
    #page-footer {
        display: flex;
        align-items: center;
        height: 60px;
    }

    #main-container {
        min-height: calc(100vh - 60px);
    }

    .map #main-container {
        height: calc(100vh - 60px);
    }

    .map-area__list-wrap {
        right: 30px;
    }
}

@media screen and (max-width: 480px) {
    .map-area__list-wrap {
        width: 100%;
        position: relative;
        right: 0;
        top: 0;
        margin-top: 17px;
    }

    .map-area__map {
        min-height: 0;
        height: 400px
    }

    .map-area__drag-btn {
        display: none;
    }
}