.hover-none:hover {
  color: #fff;
}
.defect-completed {
  color: #ccc;
}
.delete-button {
  background: none;
}
.help-block {
  color: red;
}
.table th:last-of-type {
  text-align: right;
}
#login-form_block {
  position: absolute;
  top: 20%;
}
#main-container {
  min-height: calc(100vh - 43px);
}
.login-header {
  padding: 24px 0 0 0;
}
.with-subrows {
  cursor: pointer;
}
.elpas-menu__top-background {
  background-color: white;
  padding: 0;
  margin: 0 auto !important;
  text-align: center;
  padding-top: 8px;
  height: 76px;
}
.edit-icon {
  width: 12px;
  height: 12px;
}
.custom-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  height: 77px;
}
.image-margin-fix {
  width: 48px !important;
  float: left;
}
.status-line-background {
  display: block;
  width: 90%;
  margin-left: 5%;
  height: 8px;
  background: lightgray;
  border-radius: 15px;
  margin-bottom: 8px;
}
.status-line-background .status-line {
  background: green;
  border-radius: 8px;
  height: 100%;
}
.company__company-name-wrapper {
  float: left;
}
.company__company-name-wrapper * {
  float: left;
}
.custom-breadcrumbs {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  z-index: 101;
  color: white;
  background-color: #306D9C;
  height: 40px;
  padding-top: 8px;
}
.custom-breadcrumbs * {
  color: white;
}
.breadcrumb > .active {
  color: white !important;
}
#main-container {
  background-color: #F1F3F4;
}
.block-subtitle {
  padding: 0;
  line-height: 1;
  margin: 8px 0 0 0 !important;
  font-size: 12px;
}
.block-header {
  max-height: 96px;
  background-color: #CDDBE3;
}
.block-header .block-title {
  font-size: 16px;
}
.block-content {
  padding: 0 20px 1px !important;
}
.card-body tr * {
  color: gray;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.card-body tr td:first-child {
  float: left;
  width: 100%;
}
.card-body tr td:last-child {
  width: 25%;
  text-align: right;
}
.card-body tr td a:hover {
  text-decoration: underline;
}
.card-body tr td.table-link {
  width: 100%;
  text-align: left;
}
.card-body tr td.table-link a {
  color: #0099FF;
}
.card-body tr tbody {
  margin-left: 24px;
}
.card-body .with-subrows td:first-child:after {
  float: left;
  display: block;
  margin-right: 8px;
  font-size: 14px;
  color: #306D9C !important;
}
.card-body .with-subrows.closed td:first-child:after {
  content: '+';
  font-weight: bold;
}
.card-body .with-subrows.opened td:first-child:after {
  content: '-';
  font-weight: bold;
}
.card-body .subrow td:first-child {
  margin-left: 36px;
}
.card-body .subrow.subrow-clear td:first-child {
  margin-left: 0px;
}
.show-spoiler-table {
  font-size: 14px;
  color: #306D9C !important;
  margin-left: 8px;
}
.add-new-plus {
  font-size: 24px;
  color: #306D9C;
  margin-left: 8px;
}
.table-big thead {
  background-color: #CDDBE3;
}
.table-big thead tr th {
  font-size: 12px !important;
  vertical-align: middle;
}
.table-big tbody tr td {
  font-size: 12px !important;
  vertical-align: middle;
}
.table-big tbody tr td.empty {
  color: #993366;
}
.table-big tbody tr td.empty * {
  color: #993366;
}
.table-big tbody tr td:last-child {
  text-align: right;
}
.table-big tfoot {
  background-color: #CDDBE3;
  width: 100%;
}
.table-big tfoot td:last-child {
  text-align: right;
}
.table-defects thead tr th:first-child {
  width: 1%;
}
.table-defects thead tr th:nth-child(2) {
  width: 20%;
}
.table-defects thead tr th:nth-child(3) {
  width: 15%;
}
.table-defects thead tr th:nth-child(4) {
  width: 25%;
  text-align: center;
}
.table-defects thead tr th:nth-child(5) {
  width: 15%;
}
.table-defects thead tr th:nth-child(6) {
  width: 10%;
}
.table-defects thead tr th:nth-child(9) {
  width: 20%;
}
.table-defects tbody tr td:nth-child(4) {
  text-align: center;
}
.table-single-criterias thead tr th:first-child {
  width: 5%;
}
.table-single-criterias thead tr th:nth-child(2) {
  width: 5%;
}
.table-single-criterias thead tr th:nth-child(3) {
  width: 90%;
}
.table-single-criterias thead tr th:nth-child(4) {
  width: 5%;
}
.table-files thead tr th:first-child {
  width: 1%;
}
.table-files thead tr th:nth-child(2) {
  width: 5%;
  text-align: center;
}
.table-files thead tr th:nth-child(3) {
  width: 28%;
}
.table-files thead tr th:nth-child(4) {
  width: 40%;
}
.table-files thead tr th:nth-child(5) {
  width: 15%;
}
.table-files thead tr th:nth-child(6) {
  width: 10%;
}
.table-files tbody tr td:nth-child(2) {
  text-align: center;
}
.table-journal thead tr th:first-child {
  width: 1%;
}
.table-journal thead tr th:nth-child(2) {
  width: 5%;
  text-align: center;
}
.table-journal thead tr th:nth-child(3) {
  width: 30%;
}
.table-journal thead tr th:nth-child(4) {
  width: 20%;
}
.table-journal thead tr th:nth-child(7) {
  width: 20%;
}
.table-journal tbody tr td:nth-child(2) {
  text-align: center;
}
.table-plan thead tr th:first-child {
  width: 1%;
}
.table-plan thead tr th:nth-child(2) {
  width: 8%;
  text-align: center;
}
.table-plan thead tr th:nth-child(3) {
  width: 25%;
}
.table-plan thead tr th:nth-child(4) {
  width: 10%;
}
.table-plan tbody tr td:nth-child(2) {
  text-align: center;
}
.table-certificate-opo thead tr th:first-child {
  width: 1%;
}
.table-certificate-opo thead tr th:nth-child(2) {
  width: 1%;
}
.table-certificate-opo thead tr th:nth-child(3) {
  width: 40%;
}
.table-certificate-opo thead tr th:nth-child(4) {
  width: 20%;
}
.table-certificate-opo thead tr th:nth-child(5) {
  width: 15%;
}
.table-certificate-opo thead tr th:nth-child(6) {
  width: 15%;
}
.table-certificate-opo tbody tr.with-subrows td:nth-child(3):after {
  display: block;
  float: left;
  color: #306D9C;
  margin-right: 8px;
}
.table-certificate-opo tbody tr.closed td:nth-child(3):after {
  content: '+';
  font-weight: bold;
}
.table-certificate-opo tbody tr.opened td:nth-child(3):after {
  content: '-';
  font-weight: bold;
}
.table-certificate-opo tbody tr.subrow td:nth-child(3) {
  padding-left: 32px;
  color: lightgray;
}
.table-criterias thead tr th:first-child {
  width: 1%;
}
.table-criterias thead tr th:nth-child(2) {
  width: 5%;
}
.table-criterias tbody tr td:last-child {
  text-align: initial !important;
}
.table-podnad-buildings thead tr th:first-child {
  width: 1%;
}
.table-podnad-buildings thead tr th:nth-child(2) {
  width: 5%;
}
.table-podnad-buildings thead tr th:nth-child(3) {
  width: 10%;
}
.table-podnad-buildings thead tr th:nth-child(4) {
  width: 10%;
}
.table-podnad-buildings thead tr th:nth-child(5) {
  width: 5%;
}
.table-podnad-buildings thead tr th:nth-child(6) {
  width: 5%;
}
.table-podnad-buildings thead tr th:nth-child(7) {
  width: 5%;
}
.table-podnad-buildings thead tr th:nth-child(8) {
  width: 5%;
}
.table-podnad-buildings thead tr th:nth-child(9) {
  width: 5%;
}
.table-podnad-buildings thead tr th:nth-child(10) {
  width: 30%;
}
.table-podnad-buildings tbody tr.with-subrows td:nth-child(3):after {
  display: block;
  float: left;
  color: #306D9C;
  margin-right: 8px;
}
.table-podnad-buildings tbody tr.closed td:nth-child(3):after {
  content: '+';
  font-weight: bold;
}
.table-podnad-buildings tbody tr.opened td:nth-child(3):after {
  content: '-';
  font-weight: bold;
}
.table-podnad-buildings tbody tr.subrow td:nth-child(3) {
  padding-left: 32px;
  color: lightgray;
}
.table-podnad-buildings tbody tr td:last-child {
  text-align: initial !important;
}
.table-objects-td thead tr th:first-child {
  width: 1%;
}
.table-objects-td thead tr th:nth-child(2) {
  width: 3%;
}
.table-objects-td thead tr th:nth-child(3) {
  width: 7%;
}
.table-objects-td thead tr th:nth-child(4) {
  width: 12%;
}
.table-objects-td thead tr th:nth-child(5) {
  width: 18%;
}
.table-objects-td thead tr th:nth-child(6) {
  width: 12%;
}
.table-objects-td thead tr th:nth-child(7) {
  width: 10%;
}
.table-objects-td thead tr th:nth-child(8) {
  width: 12%;
}
.table-parts-opo thead tr th:first-child {
  width: 1%;
}
.table-parts-opo thead tr th:nth-child(2) {
  width: 1%;
}
.table-str_const thead tr th:first-child {
  width: 1%;
}
.table-str_const thead tr th:nth-child(2) {
  width: 5%;
}
.table-str_const thead tr th:nth-child(3) {
  width: 95%;
}
.table-orgs thead tr th:first-child {
  width: 1%;
}
.table-orgs thead tr th:nth-child(2) {
  width: 5%;
}
.table-orgs thead tr th:nth-child(3) {
  width: 24%;
}
.table-orgs thead tr th:nth-child(4) {
  width: 20%;
}
.table-orgs thead tr th:nth-child(5) {
  width: 20%;
}
.table-orgs thead tr th:nth-child(6) {
  width: 25%;
}
.table-orgs thead tr th:nth-child(7) {
  width: 1%;
}
.row-with-margin {
  margin-top: 24px;
  margin-bottom: 24px;
}
.filter-wrapper {
  width: 100%;
}
.filter-wrapper .filter {
  float: left;
  width: 214px;
}
.filter-wrapper .filter label {
  width: 100%;
  font-style: normal;
  font-weight: 200;
  height: 24px;
  padding: 0 0 8px 0;
}
.filter-wrapper .filter select {
  width: 100%;
  height: 28px;
}
.filter-wrapper .filter:not(:first-child) {
  margin-left: 12px;
}
.filter-wrapper .filter-button {
  margin-top: 28px;
  margin-left: 12px;
  background-color: #306D9C;
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
}
.table-button {
  padding: 4px 8px 4px 8px;
  background: 0;
  border-width: 2px;
  border-style: solid;
}
.table-button:not(:first-child) {
  margin-left: 12px;
}
.button-good {
  border-color: #339966;
  color: #339966;
}
.button-good:hover {
  background-color: #339966;
  color: white;
}
.button-bad {
  border-color: #993366;
  color: #993366;
}
.button-bad:hover {
  background-color: #993366;
  color: white;
}
.add-wrapper {
  width: 100%;
}
.add-wrapper a {
  width: 100%;
  float: right;
  text-align: right;
  color: #339966;
}
.table-pagination-wrapper {
  width: 100%;
}
.table-pagination-wrapper nav .table-pagination {
  float: right;
  padding: 0;
  margin: 0 auto;
}
.table-pagination-wrapper nav .table-pagination .page-item .page-link {
  background: 0;
}
form .form-group:first-child {
  padding-top: 24px;
}
form .form-group div div .form-hint {
  font-size: 11px;
}
form .form-group div div input[type="file"] {
  padding: 12px 0 36px 0;
}
form .form-link {
  padding-top: 16px;
}
form .form-link a {
  color: #339966;
}
.active-works-wrapper {
  width: 100%;
}
.active-works-wrapper div {
  float: left;
}
.active-works-wrapper div:not(:first-child) {
  margin-left: 12px;
}
.breadcrumb li a {
  color: white;
  text-decoration: underline;
}
.breadcrumb li a:hover {
  color: white;
  text-decoration: none;
}
.css-input.css-checkbox.css-checkbox-primary {
  width: 100%;
}
.full-width-link {
  display: block;
  width: 100%;
}
.full-width-link:not(:first-child) {
  margin-top: 8px;
}
.full-width-link:last-child {
  margin-bottom: 12px;
}
.link-bad {
  color: #993366 !important;
}
.link-bad:hover {
  color: darkred;
}
.nav-main .nav-submenu {
  position: relative;
  padding: 10px 20px;
}
.nav-main .nav-submenu a {
  display: inline;
  padding: 0;
}
.nav-main .nav-submenu:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-main .nav-submenu a:hover,
.nav-main .nav-submenu a:focus {
  color: #fff;
  background: none;
}
.nav-main .nav-submenu:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: inline-block;
  font-family: 'FontAwesome';
  color: rgba(255, 255, 255, 0.25);
  content: "\f104";
}
.nav-main li.open > .nav-submenu:before {
  content: "\f107";
}
.nav-main li.open > .nav-submenu > a {
  color: #fff;
}
.map #main-container,
.blueprint #main-container {
  height: calc(100vh - 43px);
}
.map .content,
.blueprint .content {
  padding: 55px 0 1px;
  height: 100%;
}
.map-area {
  width: 100%;
  height: 100%;
  position: relative;
}
.map-area__map {
  width: 100%;
  min-height: 700px;
  height: 100%;
}
.map-area__list-wrap {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.map-area__list-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px 25px 30px;
  border-bottom: 1px solid #EAEDEF;
}
.map-area__list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
}
.map-area__list-item {
  padding: 10px 20px 10px 30px;
  border-bottom: 1px solid #EAEDEF;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.map-area__point-title {
  max-width: 90%;
  text-align: left;
  border: none;
  box-shadow: none;
  background: none;
  font-family: inherit;
  padding: 0;
  font-size: 16px;
}
.map-area__point-title.active {
  color: #306d9c;
}
.map-area__point-title.active + span {
  color: #306d9c;
}
.map-area__open-btn {
  position: relative;
  border: none;
  box-shadow: none;
  background: none;
  font-family: inherit;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  padding: 0 20px 0 0;
  max-width: 90%;
  text-align: left;
}
.map-area__open-btn::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  right: 0;
  top: 50%;
  transform: translateY(-6px) rotate(45deg);
  transition: all 0.2s;
}
.map-area__open-btn.open::after {
  transform: translateY(-1px) rotate(225deg);
}
.map-area__drag-btn {
  border: none;
  box-shadow: none;
  height: 25px;
  width: 16px;
  background: url('../img/bento-icon.png') no-repeat center / contain;
  font-size: 0;
}
#presentationMode {
  display: none;
}
.nav-header > li > a.header-mail-link {
  height: 43px;
  line-height: 1;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 992px) {
  .nav-button-fix {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .map .content,
  .blueprint .content {
    padding: 0 0 1px;
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .button-good {
    display: block;
    text-align: center;
    width: 100%;
  }
  .content {
    margin-top: 24px;
  }
  #login-form_block {
    width: 100%;
  }
  .custom-breadcrumbs {
    display: none;
  }
  .filter-wrapper .filter {
    float: none;
    width: 100%;
  }
  .filter-wrapper .filter:not(:first-child) {
    margin-top: 12px;
    margin-left: 0;
  }
  .filter-wrapper .filter-button {
    margin: 12px 0 12px 0;
  }
  .add-wrapper a {
    float: none;
    text-align: left;
  }
  .table-button {
    width: 100%;
  }
  .table-button:not(:first-child) {
    margin-top: 12px;
    margin-left: 0px;
  }
  .active-works-wrapper {
    width: 100%;
  }
  .active-works-wrapper div {
    float: none;
  }
  .active-works-wrapper div:not(:first-child) {
    margin-left: 0;
    margin-top: 8px;
  }
  form .form-link {
    padding-top: 0;
    padding-bottom: 8px;
  }
}
@media screen and (max-width: 767px) {
  #page-footer {
    display: flex;
    align-items: center;
    height: 60px;
  }
  #main-container {
    min-height: calc(100vh - 60px);
  }
  .map #main-container {
    height: calc(100vh - 60px);
  }
  .map-area__list-wrap {
    right: 30px;
  }
}
@media screen and (max-width: 480px) {
  .map-area__list-wrap {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    margin-top: 17px;
  }
  .map-area__map {
    min-height: 0;
    height: 400px;
  }
  .map-area__drag-btn {
    display: none;
  }
}
